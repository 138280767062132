import React, { useState, useEffect} from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ProductsListingItem from '../components/ProductCard/ProductsListingItem'
import Styles from './ProductCategoriesTemplate.module.css'
import { Helmet } from "react-helmet"
import Sidebar from '../components/sidebar/sidebar'

const ProductCategoriesTemplate = ({ data }) => {
  const { shopifyCollection } = data
  const [showiPhone, setshowiPhone] = useState(true);
  const [phoneVersionFilterCurrent, setphoneVersionFilterCurrent] = useState("iPhone 12"); 
  let iphoneVersionFilterDefault = "iPhone 12" 

  const [SGphoneVersionFilterDefault, setSGphoneVersionFilterDefault] = useState("Samsung Galaxy S20"); 
  let phoneBrandFilter = "iPhone"

  
  const handleChange = (e) => {
     
     
      setphoneVersionFilterCurrent(e.target.value);
    
  }
  const handleChangeFilter = (e) => {
    
    if(e.target.value == "iPhone"){
      setshowiPhone(true);
      setphoneVersionFilterCurrent("iPhone 12");
    }else{
      setshowiPhone(false);
      setphoneVersionFilterCurrent("S20");
    }
 }
  return (
    <Layout>
      <Helmet>
        <title>{shopifyCollection.title}</title>
        <meta name="description" content={shopifyCollection.description} />
      </Helmet>
      <div className={Styles.breadcrumb}><Link className={Styles.breadcrumbLink} to="/">Home</Link>{' > '} {shopifyCollection.title}</div>

      <div className={Styles.mainDiv}>

        <Sidebar />
        
        <div className={Styles.catcontainer} itemScope itemType="https://schema.org/Collection">
          {(shopifyCollection.title == "Mandala Phone Cases" || shopifyCollection.title == "Abstract Phone Cases" || shopifyCollection.title == "Trippy Phone Cases")&&
          <div className={Styles.filtersWrapper}><p className={Styles.filterHeader}>Filters:</p>
           <div className={Styles.filtersContainer}>
            <p className={Styles.filterLabel}>Phone Brand: </p>
          <select onChange={handleChangeFilter} defaultValue={phoneBrandFilter}>
              <option value="iPhone">iPhone</option>
              <option value="Samsung Galaxy">Samsung Galaxy</option>
           
          </select>
          </div>
          <div  className={Styles.filtersContainer}>
            <p className={Styles.filterLabel}>Phone Version: </p>
            {showiPhone ?
          <select onChange={handleChange} defaultValue={iphoneVersionFilterDefault}>
              <option value="iPhone 12">iPhone 12</option>
              <option value="iPhone 12 Mini">iPhone 12 Mini</option>
              <option value="iPhone 12 Pro">iPhone 12 Pro</option>
              <option value="iPhone 12 Pro Max">iPhone 12 Pro Max</option>
              <option value="iPhone 11">iPhone 11</option>
              <option value="iPhone 11 Pro">iPhone 11 Pro</option>
              <option value="iPhone 11 Pro Max">iPhone 11 Pro Max</option>
              <option value="iPhone X">iPhone X</option>
              <option value="iPhone XR">iPhone XR</option>
              <option value="iPhone XS">iPhone XS</option>
              <option value="iPhone XS Max">iPhone XS Max</option>
              <option value="iPhone 8">iPhone 8</option>
              <option value="iPhone 8 Plus">iPhone 8 Plus</option>
              <option value="iPhone 7">iPhone 7</option>
              <option value="iPhone 7 Plus">iPhone 7 Plus</option>
          </select>
          :
          <select onChange={handleChange} defaultValue={SGphoneVersionFilterDefault}>
              {shopifyCollection.title == "Mandala Phone Cases" &&
              <><option value="S21">Samsung Galaxy S21</option>
              <option value="S21 Ultra">Samsung Galaxy S21 Ultra</option>
              <option value="S21 Plus">Samsung Galaxy S21 Plus</option></>
              }
              <option value="S20">Samsung Galaxy S20</option>
              <option value="S20 Ultra">Samsung Galaxy S20 Ultra</option>
              <option value="S20 Plus">Samsung Galaxy S20+</option>
              <option value="S10">Samsung Galaxy S10</option>
              <option value="S10 Plus">Samsung Galaxy S10 Plus</option>
              <option value="S10E">Samsung Galaxy S10E</option>
         
          </select>
          }
          </div>
          </div>
          }
          <h2 className={Styles.catheader} itemProp="name">{shopifyCollection.title}</h2>
         {(shopifyCollection.title == "Mandala Phone Cases" || shopifyCollection.title == "Abstract Phone Cases" || shopifyCollection.title == "Trippy Phone Cases") &&
          <div className={Styles.wrapper}>
            {shopifyCollection.products.map(product => (
            
              (product.productType == (phoneVersionFilterCurrent + " Case") || product.productType == phoneVersionFilterCurrent || ("Samsung Galaxy " + product.productType) == phoneVersionFilterCurrent) && 
              <ProductsListingItem key={product.id} product={product} />
            ))}
          </div>
          }

          {(shopifyCollection.title != "Mandala Phone Cases" && shopifyCollection.title != "Abstract Phone Cases" && shopifyCollection.title != "Trippy Phone Cases")&&
            <div className={Styles.wrapper}>
              {shopifyCollection.products.map(product => (
                <ProductsListingItem key={product.id} product={product} />
              ))}
            </div>
          }
        </div>

      </div>
    </Layout>
  )
}

export default ProductCategoriesTemplate

export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
            title
            description
            products{
              title
              productType
              id
              handle
              variants {
                price
              }
              images {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
   
    }
  }
`
